export default {
  LIST_SUBMITED_REPORT: 'SituationReport/list-situation-report-send',
  APPROVE_SUBMITED_REPORT: 'SituationReport/approve-report',
  REJECT_SUBMITED_REPORT: 'SituationReport/reject-report',
  LIST_NOTSUBMIT_REPORT: 'SituationReport/list-situation-report-not-send',
  SEND_MESSAGE: 'SituationReport/send-message',

  // Công bố danh sách doanh nghiệp nộp báo cáo định kỳ
  ENTERPRISES_SUBMIT_REPORT_CREATE: 'AnnouncementOfEnterprisesSubmitReport/create',
  ENTERPRISES_SUBMIT_REPORT_UPDATE: 'AnnouncementOfEnterprisesSubmitReport/update',
  ENTERPRISES_SUBMIT_UPLOAD_FILE: 'AnnouncementOfEnterprisesSubmitReport/upload-file',
  ENTERPRISES_SUBMIT_REPORT_DELETE: 'AnnouncementOfEnterprisesSubmitReport/delete',
  ENTERPRISES_SUBMIT_REPORT_DETAIL: 'AnnouncementOfEnterprisesSubmitReport/get-by-id/',
  API_ENTERPRISES_SUBMIT_REPORT: 'AnnouncementOfEnterprisesSubmitReport/get-paging',
  COMBOBOX_ENTERPRISES_SUBMIT_REPORT: 'AnnouncementOfEnterprisesSubmitReport/get-combobox',
  ENTERPRISES_SUBMIT_REPORT_DOWNLOADS: 'AnnouncementOfEnterprisesSubmitReport/download-file',
}
